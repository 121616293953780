
:root{
    --background-color: #ffffff;
    --shadow-color: #f1f1f18c;

    --primary-color: #000000;
    --primary-text-color: #000000;

    --white-text-color: #ffffff;

    --secondary-color: #000000;
    --secondary-color-hover: #252525;
    --secondary-color-active: #363636;
    --secondary-text-color: #ffffff;

    --outline-color: #bdbdbd;
    --light-outline-color: #e2e2e2;

    --toast-background-color: #0e0e0e;

    --hover-color: #f1f1f1b7;
    --active-color: #ffffff;

    --grey-text-color: #808080;

    --grey-background-color: #dfdfdf;
    --light-grey-background-color: #f7f7f7;

    --yellow-hover-helper: #bdbdbd0e;

    --border-radius-g: 10px;
}

*{
    box-sizing: border-box;
}

body{
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.flex{
    display: flex;
}
.items-center{
    align-items: center;
}
.justify-center{
    justify-content: center;
}

.mb-1{
    margin-bottom: 5px;
}
.mb-2{
    margin-bottom: 10px;
}
.mb-3{
    margin-bottom: 15px;
}
.mb-4{
    margin-bottom: 20px;
}
.mb-5{
    margin-bottom: 25px;
}
.mb-6{
    margin-bottom: 30px;
}
.mb-7{
    margin-bottom: 35px;
}
.mb-8{
    margin-bottom: 40px;
}

.mt-1{
    margin-top: 5px;
}
.mt-2{
    margin-top: 10px;
}
.mt-3{
    margin-top: 15px;
}
.mt-4{
    margin-top: 20px;
}
.mt-5{
    margin-top: 25px;
}
.mt-6{
    margin-top: 30px;
}
.mt-7{
    margin-top: 35px;
}
.mt-8{
    margin-top: 40px;
}

.ml-1{
    margin-left: 5px;
}
.ml-2{
    margin-left: 10px;
}
.ml-3{
    margin-left: 15px;
}
.ml-4{
    margin-left: 20px;
}
.ml-5{
    margin-left: 25px;
}
.ml-6{
    margin-left: 30px;
}
.ml-7{
    margin-left: 35px;
}
.ml-8{
    margin-left: 40px;
}

.mr-1{
    margin-right: 5px;
}
.mr-2{
    margin-right: 10px;
}
.mr-3{
    margin-right: 15px;
}
.mr-4{
    margin-right: 20px;
}
.mr-5{
    margin-right: 25px;
}
.mr-6{
    margin-right: 30px;
}
.mr-7{
    margin-right: 35px;
}
.mr-8{
    margin-right: 40px;
}

.ms-auto{
    margin-left: auto;
}

.mw-100{
    width: 100%;
    max-width: 100px;
}
.mw-200{
    width: 100%;
    max-width: 200px;
}
.mw-300{
    width: 100%;
    max-width: 300px;
}
.mw-400{
    width: 100%;
    max-width: 400px;
}
.mw-450{
    width: 100%;
    max-width: 450px;
}
.mw-500{
    width: 100%;
    max-width: 500px;
}
.mw-600{
    width: 100%;
    max-width: 600px;
}
.mw-700{
    width: 100%;
    max-width: 700px;
}
.mw-800{
    width: 100%;
    max-width: 800px;
}
.mw-900{
    width: 100%;
    max-width: 900px;
}
.mw-1000{
    width: 100%;
    max-width: 1000px;
}
.mw-1100{
    width: 100%;
    max-width: 1100px;
}
.mw-1200{
    width: 100%;
    max-width: 1200px;
}
.mw-1300{
    width: 100%;
    max-width: 1300px;
}
.mw-1400{
    width: 100%;
    max-width: 1400px;
}

.w-full{
    width: 100%;
}

.card{
    padding: 20px;
    background-color: var(--background-color);
    width: 100%;
    border-radius: var(--border-radius-g);
}

.card-shadow{
    box-shadow: 0 11px 34px rgba(0, 0, 0, .16);
    box-shadow: 0px 0px 20px 1px var(--shadow-color);
}

.margin-top-responsive{
    margin-top: 200px;
}

@media (max-width: 800px) {
    .margin-top-responsive{
        margin-top: 20px;
    }
    .responsive-shadow{
        box-shadow: none;
    }
    .card{
        padding: 10px;
    }
}

.p-1{
    padding: 5px;
}
.p-2{
    padding: 10px;
}

.form-group{
    display: block;
}

.form-label{
    display: block;
    width: fit-content;
    font-size: 14px;
    user-select: none;
}

.form-input{
    -webkit-appearance: none;
    display: block;
    width: 100%;
    padding: 0.8rem;
    font-size: 15px;
    border-radius: var(--border-radius-g);
    border: none;
    box-shadow: inset 0px 0px 0px 1px var(--light-outline-color);
    /* border: 1px solid var(--outline-color); */
    outline: none;
    background-color: var(--background-color);
    caret-color: var(--primary-color)!important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.form-input:focus{
    outline: 2px solid var(--secondary-color);
    outline-offset: 2px;
}

.sm-header{
    font-size: 18px;
    display: block;
    width: fit-content;
    font-weight: 600;
}

.btn{
    appearance: none;
    border: none;
    width: 100%;
    padding: 0.8rem;
    font-size: 15px;
    cursor: pointer;
    border-radius: var(--border-radius-g);
}

.btn:disabled{
    cursor: not-allowed;
    filter: brightness(0.9);
}

.btn-secondary{
    color: var(--primary-text-color)!important;
}

.btn-primary{
    background-color: var(--secondary-color);
    color: var(--secondary-text-color);
}
.btn-primary:hover{
    background-color: var(--secondary-color-hover);
}
.btn-primary:active{
    background-color: var(--secondary-color-active);
}
.btn-primary:disabled{
    background-color: var(--secondary-color-hover);
    cursor: not-allowed;
}

.btn-primary-outline{
    background-color: var(--background-color);
    color: var(--secondary-color);
    box-shadow: inset 0px 0px 1px 1px var(--secondary-color);
    -webkit-appearance: none;
}
.btn-primary-outline:disabled{
    filter: brightness(1.2);
    cursor: not-allowed;
}

.navigation-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    height: 60px;
    background-color: var(--background-color);
    /* box-shadow: 0px 2px 10px 1px var(--shadow-color); */
    padding: 20px;
    z-index: 4;
}

@media (max-width: 1480px) {
    .navigation-bar{
        padding: 10px;
    }
}

.navigation-logo{
    display: block;
    width: fit-content;
    font-size: 16px;
    color: var(--primary-text-color);
    font-weight: 400;
    transform-origin: left;
    transform: scale(1.5, 1.1);
}   

.container{
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* margin-top: 30px; */
    padding: 20px;
}
@media (max-width: 800px){
    .container{
        padding: 10px;
    }
}
.md-header{
    display: block;
    width: fit-content;
    font-size: 22px;
    color: var(--primary-text-color);
    font-weight: 500;
}

.justify-end{
    justify-content: end;
}

.ul-list{
    list-style: none;
    margin: 0;
    padding: 0;
}

.modal-container{
    position: fixed;
    left: 0px;
    right: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-top: 60px; */
    z-index: 3;
    overflow-y: auto;
    max-height: 95%;
}

@media (max-width: 800px){
    .modal-header{
        border-radius: 0px!important;
    }
    .modal-body{
        border-radius: 0px!important;
        /* height: 100%; */
    }

    #modal-container-width{
        width: 100%!important;
        max-width: 100%!important;
    }

    .modal-container{
        margin-top: 0px;
        top: 0;
        bottom: 0;
        max-height: 100%;
    }

    /* .modal-body{
        height: 100%;
    } */
}


.modal-background{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-in-out;
}

#modal-title{
    display: block;
    width: fit-content;
    color: var(--primary-text-color);
    font-size: 16px;
    font-weight: 500;
}

.modal-header{
    background-color: var(--background-color);
    border-top-left-radius: var(--border-radius-g);
    border-top-right-radius: var(--border-radius-g);
    padding: 20px;
}
.modal-body{
    background-color: var(--background-color);
    border-bottom-left-radius: var(--border-radius-g);
    border-bottom-right-radius: var(--border-radius-g);
    padding: 20px;
    overflow-y: auto;
}

.btn-icon{
    appearance: none;
    border: none;
    width: 35px;
    height: 35px;
    border-radius: var(--border-radius-g);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    color: var(--primary-text-color)!important;
}
.btn-icon:hover{
    background-color: var(--hover-color);
}
.btn-icon-span{
    font-size: 20px;
    color: var(--primary-text-color)!important;
}

.element-outline{
    box-shadow: inset 0px 0px 0px 1px var(--light-outline-color);
}

.optional-label{
    display: block;
    width: fit-content;
    font-size: 12px;
}

.btn-dropdown{
    appearance: none;
    border: none;
    background-color: rgba(0,0,0,0);
    padding: 0.4rem;
    border-radius: var(--border-radius-g);
    cursor: pointer;
}
/* .btn-dropdown:hover{
    background-color: var(--hover-color);
} */

.dropdown-label{
    display: block;
    width: fit-content;
    font-size: 14px;
    color: var(--primary-text-color);
}

.dropdown-container{
    position: absolute;
    top: 60px;
    background-color: var(--background-color);
    right: 0;
    width: 150px;
    box-shadow: 0 11px 34px rgba(0, 0, 0, .16);
    -webkit-appearance: none;
    padding: 0px;
    border-radius: var(--border-radius-g);
    transition: 0.2s ease-in-out;
    z-index: -1;
}

/* .dropdown-item:first-child{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: var(--border-radius-g);
    border-top-right-radius: var(--border-radius-g);
}
.dropdown-item:only-child{
    border-radius: var(--border-radius-g)!important;
}
.dropdown-item:last-child{
    border-bottom-left-radius: var(--border-radius-g);
    border-bottom-right-radius: var(--border-radius-g);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
} */

.dropdown-item{
    display: flex;
    align-items: center;
    padding: 0.6rem 0.8rem;
    border-radius: 0px;
    cursor: pointer;
}
.dropdown-item:hover{
    background-color: var(--hover-color);
}

.dropdown-item-label{
    display: block;
    width: fit-content;
    font-size: 14px;
    color: var(--primary-text-color);
}

.subtitle-label{
    display: block;
    width: fit-content;
    font-size: 14px;
    color: var(--primary-text-color);
    font-weight: 500;
}

.sm-label{
    display: block;
    width: fit-content;
    font-size: 14px;
    color: var(--primary-text-color);
}

.xs-label{
    display: block;
    width: fit-content;
    font-size: 12px;
    color: var(--primary-text-color);
}

.mx-auto{
    margin-left: auto;
    margin-right: auto;
}

.link{
    display: block;
    width: fit-content;
    text-decoration: underline;
    color: var(--primary-text-color);
    font-size: 14px;
    cursor: pointer;
}

.secondary-text-color{
    color: var(--secondary-color);
}

.secondary-white-text-color{
    color: var(--secondary-text-color);
}

.loading-scan-div{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.7);
    border-radius: 20px;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.sm-loader{
    border-width: 2px!important;
    width: 18px!important;
    height: 18px!important;
}

.black-loader{
    border: 5px solid #000;
    border-bottom-color: transparent !important;
}

.grey-loader{
    border: 5px solid var(--outline-color);
    border-bottom-color: transparent !important;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

.line-break{
    width: 100%;
    background-color: var(--outline-color);
    height: 1px;
}

.btn-secondary-outline{
    background-color: var(--background-color);
    box-shadow: inset 0px 0px 0px 1px var(--outline-color);
    -webkit-appearance: none;
}

.btn-navigation{
    font-size: 14px;
    background-color: var(--background-color);
    
}
.btn-navigation:hover{
    background-color: var(--hover-color);
}
.btn-navigation-selected{
    background-color: rgba(53, 53, 53, 0.096)!important;
    color: rgb(0, 0, 0)!important;
    
}
.btn-navigation-selected:hover{
    background-color: rgba(80, 80, 80, 0.15)!important;
}

.btn-sm{
    padding: 0.4rem;
}

table {
    width: 100%;
    border-collapse: collapse;
}

tr:hover td{
    background-color: var(--yellow-hover-helper);
}

th, td {
    padding: 15px;
    text-align: left;
    background-color: var(--background-color);
}

th{
    font-size: 12px;
    font-weight: 400;
    color: var(--grey-text-color);
    border-bottom: 1px solid var(--light-outline-color);
}
td{
    font-size: 12px;
    border-bottom: 1px solid var(--light-outline-color);
    color: var(--primary-text-color);
}

.mk-map-view{
    height: 300px!important;
}

.box{
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0.4rem;
    border-radius: var(--border-radius-g); */
    background-color: var(--background-color);
    /* box-shadow: 0px 0px 10px 1px var(--shadow-color); */
}

.circle{
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.greyed-label{
    display: block;
    width: fit-content;
    font-size: 12px;
    color: var(--grey-text-color);
    font-weight: 500;
}

.link-label{
    display: block;
    width: fit-content;
    font-size: 12px;
    color: var(--primary-text-color);
    text-decoration: underline;
    cursor: pointer;
}
.link-label:hover{
    color: var(--grey-text-color);
}

.row{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
}

@media (max-width: 800px) {
    .row{
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr))!important;
    }

    .capacity-container{
        height: 150px!important;
        max-width: 100px!important;
    }

    .lg-label{
        font-size: 24px!important;
    }
}

.capacity-container{
    position: relative;
    background-color: var(--light-outline-color);
    max-width: 100px;
    width: 100%;
    border-radius: 5px;
    height: 200px;
    display: flex;
    align-items: end;
    justify-content: center;
}

.sm-capacity-container{
    position: relative;
    background-color: var(--light-outline-color);
    max-width: 40px;
    width: 100%;
    border-radius: 5px;
    height: 60px;
    display: flex;
    align-items: end;
    justify-content: center;
}

.filled-capacity{
    border-radius: 5px;
    background-color: rgb(18, 255, 49);
    width: 100%;
}

.lg-label{
    display: block;
    width: fit-content;
    font-size: 30px;
    color: var(--primary-text-color);
    font-weight: 700;
}   

.canvasjs-chart-credit{
    display: none!important;
}

.table-head{
    display: grid;
    grid-gap: 5px;
}

.bold-label{
    display: block;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-text-color);
}

.sm-btn{
    appearance: none;
    padding: 0.4rem 0.4rem;
    border: none;
    box-shadow: 0px 0px 0px 1px var(--light-outline-color);
    -webkit-appearance: none;
    background-color: var(--background-color);
    color: var(--grey-text-color);
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
}
.sm-btn:hover{
    background-color: var(--hover-color);
}

.form-checkbox{
    width: 15px;
    height: 15px;
}

.form-radio{
    width: 15px;
    height: 15px;
}

.sidebar-header{
    display: block;
    width: fit-content;
    font-size: 11px;
    transform: scale(1.1, 1);
    color: #7a7a7a;
    margin-left: 4px;
    margin-bottom: 10px;
}

.sidebar-item{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.6rem;
    height: 45px;
    border-radius: var(--border-radius-g);
    margin: 5px 0px 5px 0px;
    background-color: var(--background-color);
    color: #555555;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.sidebar-item:hover{
    background-color: #f5f7f9;
    color: #000;
}

.sidebar-item-icon{
    font-size: 24px;
}

.sidebar-item-label{
    display: block;
    width: fit-content;
    font-size: 15px;
    font-weight: 400;
}

#sidebar-container{
    transform: translateX(-999px);
    opacity: 0;
    transition: 0.2s ease-in-out;
}

.card-border{
    border: 1px solid rgba(230,230,230,0.4);
    box-shadow: 0px 0px 10px 1px rgba(230,230,230,0.4);
}

.calendar-months{
    display: flex;
    align-items: center;
    width: 100%;
}
  
.calendar-days{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
}

.calendar-item{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

@media (max-width: 800px){
  .calendar-item{
    height: auto;
    padding: 5px;
  }
  .calendar-item span{
    font-size: 14px;
  }
  
}

.upload-img-container{
    position: relative;
    margin: 20px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-img-container-lg{
    position: relative;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-img{
    max-height: 100px;
    max-width: 100px;
    border-radius: 5px;
}

.upload-single-img{
    max-height: 960px;
    max-width: 600px;
    border-radius: 5px;
}
@media (max-width: 800px) {
    .upload-single-img{
        max-height: 100%;
        max-width: 100%;
    }
}

input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 100%;
	height: 45px;
    border-radius: var(--border-radius-g);
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}

.spinning-logo-loader{
    display: block;
    text-align: center;
    font-size: 60px;
    font-weight: 700;
    color: #cacaca;
    animation: 1.2s spinning_logo infinite;
    transition: 0.2s ease-in-out;
}

@keyframes spinning_logo {
    0%{
        transform: rotateY(0deg) scale(2.1, 1.5);
    }
    50%{
        transform: rotateY(180deg) scale(2, 1.5);
    }
    100%{
        transform: rotateY(360deg) scale(2.1, 1.5);
    }
}

.level-items{
    width: 50px;
    height: 50px;
    background-color: var(--grey-background-color);
    border-radius: 0px;
}
.level-items:hover{
    filter: brightness(0.9);
}

.level-items:nth-child(2) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.level-items:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}